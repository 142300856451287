<!--
* @description:
* @fileName shopManage.vue
* @author lxs
* @date 2022/08/30 14:21
!-->
<template>
  <div>
    <vab-query-form class="ml-10" style="padding-top: 20px">
      <vab-query-form-left-panel :span="24">
        <el-form :inline="true" :model="state.queryForm" @submit.prevent>
          <el-form-item label="票种名称">
            <el-input
              v-model="state.name"
              class="mr-10"
              placeholder="请输入票种名称"
            />
          </el-form-item>
          <el-form-item label="订单状态">
            <el-select
              v-model="state.queryForm.type"
              class="mr-10"
              placeholder="请选择订单状态"
              @change="handleChangeSelect"
            >
              <el-option
                v-for="item in state.typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="日期选择">
            <el-date-picker
              v-model="date"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="dateChange"
              @calendar-change="calendarChange"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="handleQueryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table v-loading="loading" border :data="state.list">
      <el-table-column
        align="center"
        label="订单编号"
        prop="orderNo"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="门票名称"
        prop="ticketName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="票种关联"
        prop="meetingName"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="订单金额(元)"
        prop="orderPrice"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="下单人姓名"
        prop="name"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          {{ row.name ? row.name : '暂无' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="下单人手机号"
        prop="mobile"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          {{ row.mobile ? row.mobile : '暂无' }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="下单时间"
        prop="orderTime"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          {{ row.orderTime ? row.orderTime : '暂无' }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="支付时间"
        prop="payTime"
        show-overflow-tooltip
      >
        <template #default="{ row }">
          {{ row.payTime ? row.payTime : '暂无' }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="订单状态" prop="orderStatus">
        <template #default="{ row }">
          {{ filterOrderStatus[row.orderStatus] }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        label="操作"
        show-overflow-tooltip
        width="300"
      >
        <template #default="{ row }">
          <el-button
            v-if="row.orderStatus == 'REFUND_AUDIT'"
            type="primary"
            @click="handleARefundAudit(row)"
          >
            退款审核
          </el-button>
          <el-button type="primary" @click="handleJumpOrderDetail(row)">
            订单详情
          </el-button>

          <el-button type="danger" @click="deleteOrderTicket(row)">
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <!-- 
            :layout="state.layout"
      :total="state.total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :page-sizes="state.pageSizes"
      v-model:page-size="state.queryForm.pageSize"
      v-model:currentPage="state.queryForm.pageNum"
     -->
    <el-pagination
      v-if="state.total > 10"
      background
      :layout="state.layout"
      :total="state.total"
      :page-sizes="state.pageSizes"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      v-model:page-size="state.queryForm.pageSize"
      v-model:currentPage="state.queryForm.pageNum"
    />
    <refund-audit ref="refRefundDialog" />
  </div>
</template>
<script>
  export default { name: 'OrderList' }
</script>
<script setup>
  import { getCurrentInstance, onActivated, reactive, ref } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { Search } from '@element-plus/icons'
  import {
    orderList,
    reqRefundAudit,
    deleteOrderDetail,
  } from '@/api/exhibitionManage'
  import { parseTime } from '@/utils/index'
  import { useComponent } from './components/index'
  import { debounce } from '@/utils/debounce'
  import { ElMessageBox } from 'element-plus'
  // 引入组件
  const { refundAudit } = useComponent()
  const refRefundDialog = ref(null)
  // 引入路由实例
  const router = useRouter()
  const route = useRoute()
  // 引入组件
  const { proxy } = getCurrentInstance()
  const loading = ref(false) // 列表动画加载
  // 订单状态
  const filterOrderStatus = {
    PAY_WAIT: '待支付',
    PAY_PART: '部分支付',
    PAY_OVER: '已支付',
    REFUND_PART: '部分退款',
    REFUND_APPLY: '退款申请',
    REFUND_AUDIT: '退款审批中',
    REFUND: '全部退款',
    CLOSED: '已关闭',
    null: '暂无',
  }
  const state = reactive({
    pageSizes: [10, 20, 30],
    queryForm: {
      orderStatus: '',
      pageNum: 1,
      pageSize: 20,
      ticketCode: '',
      startCreateTime: '',
      endCreateTime: '',
    },
    name: '',
    total: 0,
    list: [],
    layout: 'total, sizes, prev, pager, next, jumper',

    typeOptions: [
      {
        label: '全部',
        value: '',
      },
      {
        label: '待支付',
        value: 'PAY_WAIT',
      },
      {
        label: '部分支付',
        value: 'PAY_PART',
      },
      {
        label: '已支付',
        value: 'PAY_OVER',
      },
      {
        label: '部分退款',
        value: 'REFUND_PART',
      },
      {
        label: '退款申请',
        value: 'REFUND_APPLY',
      },
      {
        label: '退款审批中',
        value: 'REFUND_AUDIT',
      },
      {
        label: '全部退款',
        value: 'REFUND',
      },
      {
        label: '已关闭',
        value: 'CLOSED',
      },
    ],
  })
  const date = ref(null)
  const startDate = ref(null)
  const dateChange = (dates) => {
    date.value = dates
    console.log('date.value', date.value)
    state.queryForm.startCreateTime = date.value['0']
    state.queryForm.endCreateTime = date.value['1']
    if (dates === null || dates.length === 0) {
    }
  }
  // const calendarChange = (dates) => {
  //   // 记录选择的开始日期，方便后面根据开始日期限定结束日期
  //   let hasSelectDate = dates !== null && dates.length > 0
  //   startDate.value = hasSelectDate ? dates[0] : null
  //   console.log('startDate.value', startDate.value)
  // }
  const handleARefundAudit = debounce(async (row) => {
    try {
      let resp = await reqRefundAudit(row.orderNo)
      proxy.$baseMessage(`退款成功`, 'success', 'vab-hey-message-success')
      emit('noticeRefresh')
    } catch (err) {
      proxy.$baseMessage(`退款失败`, 'error', 'vab-hey-message-error')
    } finally {
    }
  })
  // 获取初始化数据
  const getData = async () => {
    loading.value = true
    let params = {
      orderStatus: state.queryForm.orderStatus,
      pageNum: state.queryForm.pageNum,
      pageSize: state.queryForm.pageSize,
      ticketCode: state.queryForm.ticketCode,
      ticketName: state.name,
      startCreateTime: state.queryForm.startCreateTime,
      endCreateTime: state.queryForm.endCreateTime,
    }
    const { data } = await orderList(params)
    state.list = data.data
    state.total = data.total
    setTimeout(() => {
      loading.value = false
    }, 500)
  }
  // 筛选时间
  const filterTime = (val) => {
    return parseTime(val)
  }
  // 下拉事件
  const handleChangeSelect = (e) => {
    state.queryForm.orderStatus = e
  }
  // 查询
  const handleQueryData = debounce(() => {
    state.queryForm.pageNum = 1
    getData()
  })
  // 条数
  const handleSizeChange = debounce((pageSize) => {
    state.queryForm.pageSize = pageSize
    getData()
  })
  // 页码
  const handleCurrentChange = debounce((pageNum) => {
    state.queryForm.pageNum = pageNum
    getData()
  })

  const deleteOrderTicket = debounce((row) => {
    ElMessageBox.confirm('确认删除订单门票', '提示', {
      distinguishCancelAndClose: true,
      confirmButtonText: '确认',
      cancelButtonText: '取消',
    }).then(() => {
      deleteOrderDetail(row.orderNo).then((res) => {
        console.log(res)
        getData()
      })
    })
  })
  // 跳转订单详情
  const handleJumpOrderDetail = (row) => {
    router.push({
      path: '/exhibitionManage/orderDetail',
      query: {
        orderNo: row.orderNo,
      },
    })
  }
  onActivated(() => {
    state.queryForm.ticketCode = route.query.ticketCode || ''
    state.queryForm.ticketName = route.query.ticketName || ''
    getData()
  })
</script>
